import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import IndustryHero from "../components/Industry-hero";
import IndustryContactSection from "../components/industry-contact-section";
import IndustryImageSection from "../components/industry-image-section";
import IndustryResults from "../components/industry-results-section";
import IndustryWeDeliver from "../components/industry-we-deliver";
import IndustryWhatWeDo from "../components/industry-what-we-do";
import Layout from "../components/layout";
import Logos from "../components/logos";
import ReviewSection from "../components/review-section";

const ServicesForAccountantsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "accountant-services" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			foehImg: wpMediaItem(title: { eq: "FOEH-page" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			jasonImg: wpMediaItem(title: { eq: "Jason-industry" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bgImg: wpMediaItem(title: { eq: "Results-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Services For Accountants" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpWhatWeDoSection(
				whatWeDoFields: { page: { in: "Services for Accountants" } }
			) {
				whatWeDoFields {
					page
					servicesProvided {
						branding
						consultancy
						eCommerce
						googleAds
						seo
						sustainableWeb
						training
						webDesign
						webDevelopment
					}
				}
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services For Accountants",
				item: {
					url: `${siteUrl}/services-for-accountants`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services-for-accountants`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<IndustryHero
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={
							<span>
								TITLE OF
								<span className="text-secondary">INDUSTRY</span>
							</span>
						}
						description="Bespoke digital marketing for growing and ambitious accountancy firms"
					/>
				</section>
				<IndustryImageSection
					image={data.jasonImg.gatsbyImage}
					imageAlt={data.jasonImg.altText}
					heading="WHY WORK WITH US"
					text1="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
					text2="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
				/>
				<Logos />
				<IndustryWeDeliver
					title={
						<span>
							WE DELIVER <span className="text-secondary">RESULTS</span>
						</span>
					}
					backgroundImage={data.bgImg.gatsbyImage}
					item1Heading="Duis aute irure dolorena"
					item1Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
					item2Heading="Duis aute irure dolorena"
					item2Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
					item3Heading="Duis aute irure dolorena"
					item3Text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
				/>
				<IndustryResults
					heading="Lorem ipsum dolor sit amet Consectetur adipiscing elit"
					image={data.foehImg.gatsbyImage}
					imageAlt={data.foehImg.altText}
					imgHeight="35rem"
					text={
						<div>
							<h3 className="text-primary fs-2 ">
								Sed ut perspiciatis{" "}
								<span className="text-secondary"> unde</span>
							</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
							<h3 className="text-primary mt-5 fs-2">
								Ullamco laboris <span className="text-secondary"> nisi</span>
							</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
							<p>
								Eut enim ad minim veniam, quis nostrud exercitation ullamco
								laboris nisi ut aliquip ex ea commodo consequat.
							</p>
						</div>
					}
					bottomContent={
						<Row className="g-5 bx-lg-7">
							<Col className="text-center" lg={4}>
								<h3 className="display-4 ssp-bold text-primary">30%</h3>
								<p className="ssp-semibold" style={{ fontSize: "100%" }}>
									Lorem ipsum dolor sit
									<br /> amet
								</p>
							</Col>
							<Col className="text-center" lg={4}>
								<h3 className="display-4 ssp-bold text-primary">6</h3>
								<p className="ssp-semibold" style={{ fontSize: "100%" }}>
									Average months for
									<br /> Return on Investment
								</p>
							</Col>
							<Col className="text-center" lg={4}>
								<h3 className="display-4 ssp-bold text-primary">60%</h3>
								<p className="ssp-semibold" style={{ fontSize: "100%" }}>
									Lorem ipsum dolor sit
									<br /> amet
								</p>
							</Col>
						</Row>
					}
				/>
				<IndustryContactSection
					title="WE DELIVER RESULTS"
					text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
				/>
				<IndustryWhatWeDo WhatWeDo={data.wpWhatWeDoSection} />
				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="Web Design Eastbourne Reviews"
						page="Our Web Services"
					/>
				</section>
				{/* <OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default ServicesForAccountantsPage;
